import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";

const PrivacyPage = () => (
    <Layout>
        <SEO title="Catering Service Rio - Cotize su evento hoy misno" />
        <section className="grey py-4">
            <div className="container">
                <h3>Políticas de Privacidad</h3>
            </div>
        </section>
        <section className="py-4">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div>
                            Esta política de privacidad, que se aplica cualquiera que sea el medio o la vía utilizada para acceder al sitio cateringservicerio.com o sus subdominios y sus servicios (en lo sucesivo, el "Sitio"), describe las condiciones en las que,
                            al utilizar el Sitio, recopilamos, usamos y almacenamos información sobre usted, incluyendo sus datos personales, así como sus opciones con respecto a la recopilación, uso y divulgación de estas informaciones.<br />
                            <br />
                            Al acceder y/o utilizar la página, usted reconoce haber leído, comprendido y aceptado estar sujeto a todos los términos y condiciones de este documento. Si no está de acuerdo con esta política, no debe utilizar la página, ni sus
                            servicios relacionados, ni compartir información personal con nosotros.
                        </div>

                        <article className="py-2">
                            <h3>Artículo 1 - Identidad de la persona que recoge los datos personales</h3>

                            <p>La persona encargada de la política de privacidad es :</p>

                            <p>
                                La sociedad <strong>Catering Service Rio </strong>, sociedad anónima, inscrita en el Registro de Costa Rica, cuyo domicilio social
                                se encuentra en Tacacorí de Alajuela, Alajuela, Costa Rica (en adelante denominado "<strong>Catering Service Rio </strong>", "nosotros").
                            </p>
                        </article>

                        <article className="py-2">
                            <h3>Artículo 2 - Operaciones en las que se recogen datos personales</h3>

                            <p>Se puede navegar en la mayoría del Sitio sin necesidad de identificarse, ni comunicar tus datos personales.</p>

                            <p>Se te requerirá proporcionar información personal :</p>
                            <ul>
                                <li>Crea una cuenta ;</li>
                                <li>Encuentra al mejor profesional ;</li>
                                <li>Suscríbete a nuestro boletín ;</li>
                                <li>Contacta con <strong>Catering Service Rio </strong> ;</li>
                                <li>Enviar un comentario</li>
                            </ul>
                            <p>Algunos datos personales que te podemos pedir son obligatorios. El carácter obligatorio de los datos recogidos se indica con un asterisco o un triángulo rojo, los demás datos son opcionales.</p>
                        </article>

                        <article className="py-2">
                            <h3>Artículo 3 - Los datos personales recogidos</h3>
                            <p><strong>Catering Service Rio </strong> recoge tus siguientes datos personales :</p>
                            <ul>
                                <li>Apellido, nombre, dirección, correo electrónico, teléfono ;</li>
                                <li>Tu nombre de usuario y contraseña ;</li>
                                <li>Informaciones respecto al pago, incluyendo la información de tarjetas de crédito ;</li>
                                <li>Tus comentarios ;</li>
                                <li>Tu foto ;</li>
                                <li>Tu dirección IP, conexión e historial de pedidos, preferencias, intereses, productos vistos</li>
                                <li>
                                    Para profesionales : nombre de la empresa, descripción de las actividades de la empresa, año de constitución de la empresa, número de empleados, servicios ofrecidos, páginas de Facebook y Twitter, número NIF/CIF/Registro
                                    Mercantil.
                                </li>
                            </ul>
                        </article>

                        <article className="py-2">
                            <h3>Artículo 4 – El objetivo de la recompensa</h3>
                            <p><strong>Catering Service Rio </strong> recopila datos personales para diferentes objetivos para el usuario del Sitio :</p>
                            <ul>
                                <li>
                                    Solicitudes :<br />
                                    <strong>Catering Service Rio </strong> recoge los datos personales de los clientes para gestionar sus peticiones de servicios, incluyendo la transmisión de las solicitudes a los profesionales para comunicar los presupuestos de los
                                    profesionales, para hacer frente a cualquier reclamación. Además, <strong>Catering Service Rio </strong> recopila los datos personales de los profesionales para administrar sus cuentas, enviarles solicitudes de servicios, mostrar
                                    sus perfiles, administrar el consumo de crédito, pagar facturas y procesar cualquier reclamo. Además, <strong>Catering Service Rio </strong> puede analizar tus datos para mejorar y desarrollar nuevos servicios, identificar las
                                    tendencias en el uso del sitio y determinar la eficacia de sus campañas promocionales. Además, estos datos se conservarán con el fin de cumplir con diversos requisitos legales y reglamentarios, en particular para permitirnos
                                    establecer una prueba de un derecho o un contrato.
                                </li>
                                <li>
                                    Boletines :<br />
                                    <strong>Catering Service Rio </strong> puede utilizar los detalles de tu contacto para enviarte ofertas comerciales y de promociones. Tan pronto como crees tu cuenta o, desde la sección "Notificaciones" de tu área de cliente,
                                    puedes gestionar el envío de boletines de noticias.
                                </li>
                                <li>
                                    Compartir en las redes sociales<br />
                                    El sitio cuenta con herramientas de uso compartido que te permiten darle a "me gusta" o compartir una página de producto en las siguientes redes sociales: Facebook, Twitter. Al compartir contenido en estas redes sociales,
                                    usted se somete a su propia política de protección de datos personales, la cual le animamos a consultar para mejorar su información con respecto al uso de sus datos personales.
                                </li>
                                <li>Realización de las solicitudes y / o encuestas ;</li>
                                <li>Aplicación de estadísticas comerciales ;</li>
                                <li>Organización de concursos, loterías y cualquier promoción especial. ;</li>
                                <li>Gestión de las solicitudes de acceso, rectificación y oposición ;</li>
                                <li>Cobro de deudas y gestión de litigios ;</li>
                                <li>Gestión de comentarios sobre los servicios o contenidos.</li>
                            </ul>
                        </article>

                        <article className="py-2">
                            <h3>Artículo 5 - Los destinatarios de los datos personales recogidos</h3>
                            <p>Los datos personales recopilados están destinados a <strong>Catering Service Rio </strong> y a nuestros socios y nos permite ofrecerte servicios de alta calidad en nuestra plataforma. Gracias.</p>
                            <p>Además, los datos sobre clientes que puedan ser útiles para llevar a cabo los servicios asignados, pueden ser comunicados a los profesionales y viceversa.</p>
                            <p>
                                Además, <strong>Catering Service Rio </strong> puede publicar sus solicitudes y evaluaciones en sitios web o aplicaciones de terceros. Estas evaluaciones y solicitudes, que contienen solo su nombre y la primera letra de su apellido,
                                son visibles al público.
                            </p>
                            <p>
                                En conformidad con las obligaciones legales, <strong>Catering Service Rio </strong> también puede revelar datos a terceros cuando sea necesario para investigar, prevenir, tomar acciones al respecto sobre actividades ilegales, supuesto
                                fraude, situaciones que puedan suponer amenazas potenciales. O si creemos, en nuestra opinión, que su uso es incompatible con este documento o en los términos y condiciones generales de uso del sitio.
                            </p>
                            <p>
                                Además, los datos personales podrán ser comunicados a un tercero si <strong>Catering Service Rio </strong> se ve obligado a hacerlo por ley o regulación, por orden judicial, o si dicha divulgación es necesaria en el contexto de una
                                investigación o un procedimiento, en el territorio nacional o en el extranjero.
                            </p>
                            <p>
                                Para garantizar un buen desempeño de nuestros servicios, en particular la gestión de nuestras cuentas de clientes y profesionales, los datos a los que se refiere el Artículo 3 de este documento pueden ser transferidos a nuestros
                                proveedores de servicios ubicados en un país extranjero (Estados Unidos, Brasil, Bangladesh, India, Madagascar, Marruecos, Rusia, Ucrania). A fin de garantizar una protección óptima de sus datos llevamos a cabo estas
                                transferencias ya sea como parte del Privacy Shield | RGPD o dentro del marco de las cláusulas contractuales estándar. Las mismas son concluidas por nuestros subcontratistas y definidas por la Comisión Europea.
                            </p>
                        </article>

                        <article className="py-2">
                            <h3>Artículo 6 – Recursos técnicos para proteger los datos personales</h3>
                            <p>Usted reconoce que no existe una total seguridad en Internet.</p>
                            <p>
                                Sin embargo, <strong>Catering Service Rio </strong> ha implementado medidas de seguridad estándar, técnicas y organizativas, para proteger sus datos contra manipulaciones accidentales o intencionadas, pérdida, destrucción, comunicación
                                o cualquier acceso no autorizado a la información que recopilamos en línea.
                            </p>
                            <p>El acceso al área de clientes que contiene tus datos personales es sólo posible a través de los datos de usuario y contraseña. Esta información no debe ser distribuía a otras personas.</p>
                        </article>

                        <article className="py-2">
                            <h3>Artículo 7 - Políticas de privacidad de datos</h3>
                            <p>De acuerdo con las nuevas regulaciones sobre el uso de sus datos personales, usted tiene los siguientes derechos:</p>
                            <p>Se puede modificar en cualquier momento tus datos personales directamente desde tu cuenta de Profesional o tu cuenta de cliente de Catering Service Rio.</p>
                            <p>
                                Usted puede ejercer su derecho de acceso escribiendo a la siguiente dirección de correo electrónico: dto@cateringservicerio.com. En tales casos, antes de emprender la implementación de este derecho, podemos solicitar una prueba de la
                                identidad del usuario para verificar su exactitud.
                            </p>
                            <p>
                                Usted puede solicitar la rectificación o eliminación de sus datos personales. Podemos retener cierta información sobre usted, cuando la ley lo requiera o cuando tengamos un motivo legítimo para hacerlo, especialmente si creemos
                                o detectamos fraude o violaciones a nuestros Términos del Servicio.
                            </p>
                            <p>
                                Usted tiene derecho a oponerse en cualquier momento al uso de sus datos personales con fines de prospección comercial. También puede gestionar el envío de nuestros boletines informativos u oponerse a dicho envío, sin cargo, tan
                                pronto como cree su cuenta. Puede hacerlo desde la sección "Notificaciones" en su área de cliente o siguiendo los pasos para darse de baja que se presentan en cada boletín que le enviamos.
                            </p>
                            <p>Usted tiene derecho a limitar el procesamiento realizado en sus datos bajo las condiciones definidas por el Artículo 18.1 del Reglamento General de Protección de Datos (RGPD).</p>
                            <p>Usted tiene derecho a presentar una queja ante la autoridad de supervisión competente si considera que no hemos respetado sus derechos.</p>
                            <p>
                                Usted tiene derecho a la portabilidad de datos; es decir, a recibir estos datos en un formato estructurado, utilizado comúnmente, legible por máquina. Cualquier solicitud de portabilidad puede realizarse a la siguiente
                                dirección: dpo@cateringservicerio.com
                            </p>
                        </article>

                        <article className="py-2">
                            <h3>Artículo 8 - La duración del almacenamiento de sus datos personales</h3>
                            <p>Sus datos personales se conservarán durante el tiempo necesario para lograr los fines descriptos anteriormente, en el Artículo 4 de este documento.</p>
                            <p>Todos los datos utilizados con fines de prospección comercial son eliminados en un plazo máximo de tres años desde la ejecución del contrato o desde el último contacto con el cliente.</p>
                            <p>Más allá de este plazo, sus datos solo se guardarán para cumplir con nuestras obligaciones legales o reglamentarias o para permitirnos demostrar un derecho o contrato.</p>
                        </article>

                        <article className="py-2">
                            <h3>Artículo 9 – El tratamiento de datos personales por los sitios web de terceros</h3>
                            <p>Este documento se aplica únicamente a las páginas en el sitio a través de las cuales <strong>Catering Service Rio </strong> recoge algunos de sus datos personales y no a sitios web de terceros.</p>
                            <p>
                                El Sitio puede contener enlaces a otros sitios web o funciones que no están sujetos a nuestra política de privacidad y donde las prácticas relativas a la política de privacidad pueden diferir de las nuestras.
                                <strong>Catering Service Rio </strong> no posee ni opera estos sitios web.
                            </p>
                            <p>
                                Los usuarios deben ser conscientes de los avisos de privacidad proporcionados por los sitios web, como <strong>Catering Service Rio </strong> no es responsable y no tiene control sobre la información recopilada, utilizada, divulgada y
                                más generalmente manejada por sitios de Internet de terceros.
                            </p>
                            <p>
                                Además, hipervínculos a sitios web asociados a veces ofrecen juegos competitivos o promociones. Redirigiéntote a los sitios referidos, estos terceros pueden obtener datos personales que usted voluntariamente envíe a ellos para
                                aprovechar esta oferta. <strong>Catering Service Rio </strong> no es responsable del uso de sus datos personales por parte de estos terceros.
                            </p>
                        </article>

                        <article className="py-2">
                            <a name="cookie-policy"></a>
                            <h3>Artículo 10 - Política del sitio de cookies y otros dispositivos</h3>
                            <p>Durante su visita en el Sitio, las cookies pueden ser utilizados en su terminal y, en particular, su equipo, su tablet o smartphone.</p>
                            <p>
                                Nuestro uso de cookies está sujeto a su consentimiento previo expreso y específico, antes de que puedan ser depositadas en su terminal. Obtenemos su consentimiento a través de un cartel que incluye, por un lado, una información
                                clara y explícita sobre los fines para los que las cookies y otros dispositivos se utilizan y por otro lado la información respecto de los medios por los que usted pueda rechazar el uso de cookies.
                            </p>
                            <p>
                                Al utilizar el Sitio, usted acepta el uso de Cookies de acuerdo con este documento. Si usted no acepta el uso de estas Cookies y otros dispositivos, puede deshabilitar las cookies siguiendo las instrucciones a continuación en
                                "Desactivación de las Cookies". Sin embargo, ciertas áreas del sitio sólo se puede acceder con Cookies llamadas "necesarias" y usted debe saber que al desactivarlas por completo puede no ser capaz de acceder a determinados
                                contenidos o funcionalidades del sitio, tales como hacer una compra.
                            </p>

                            <h5>10.1 – Política de Cookies</h5>

                            <p>Las cookies son archivos de texto que pueden identificarlo como cliente y guardar sus preferencias personales (por ejemplo, sus preferencias de idioma), así como información técnica (incluidos los datos de ruta).</p>
                            <p>Además, también podemos utilizar balizas web, también llamadas "web píxel" o "Clear GIF" o tecnologías similares que se utilizan para verificar cómo se utiliza nuestro Sitio y ver qué páginas de nuestro Sitio que visita.</p>

                            <h5>10.2 - Los tipos de Cookies utilizadas por el sitio web</h5>

                            <p><strong>Catering Service Rio </strong> utiliza diferentes tipos de Cookies :</p>

                            <ul>
                                <li>
                                    Las Cookies llamadas "necesarias" le permiten beneficiarse de las funciones esenciales del Sitio. Sin estas Cookies usted no será capaz de utilizar el Sitio con normalidad y, en particular, pedir un producto.<br />
                                    Las Cookies "necesarias" son :
                                    <ul>
                                        <li>www.cateringservicerio.com symfony</li>
                                    </ul>
                                </li>
                            </ul>

                            <p>
                                Además, se requiere <strong>Catering Service Rio </strong> para colaborar con otras empresas que colocan Cookies en el Sitio. Estas empresas nos ayudan en el manejo del Sitio y ofrecen servicios adicionales. Estas Cookies no son
                                necesarias para el uso del sitio. El uso de estas Cookies por estos terceros está sujeto a sus propias condiciones de procesamiento de datos y no está cubierto por el presente documento. Estas Cookies de terceros utilizadas en
                                el Sitio son las siguientes :
                            </p>

                            <ul>
                                <li>
                                    Las Cookies de "medición de audiencia" permiten analizar el tráfico, las tendencias, los usos e identificar el mal funcionamiento del Sitio. Esto permite a <strong>Catering Service Rio </strong> mejorar su experiencia, así como el
                                    diseño y el contenido del sitio para satisfacer sus necesidades. Las Cookies de medición de audiencia son :
                                    <ul>
                                        <li>cateringservicerio.com _ga (Google Analytics)</li>
                                        <li>cateringservicerio.com _gat (Google Analytics)</li>
                                        <li>cateringservicerio.com mp_*_mixpanel (Mixpanel)</li>
                                        <li>cateringservicerio.com mp_mixpanel__c (Mixpanel)</li>
                                    </ul>

                                    Estas cookies son publicadas por Google Analytics y Mixpanel, los servicios de análisis web son proporcionados por Google Inc. y Mixpanel. Google Analytics y Mixpanel confían en las cookies para ayudar a analizar el
                                    comportamiento de los visitantes en nuestro sitio. Google Inc. y Mixpanel utilizarán esta información para evaluar la navegación de nuestro Sitio, para deducir de los diversos datos de seguimiento, compilar informes sobre la
                                    actividad del Sitio para nosotros y proporcionar otros servicios relacionados con la actividad del Sitio. Google y Mixpanel también pueden transferir esta información a terceros cuando así lo exija la ley, o cuando terceros
                                    procesen dichos datos en nombre de Google. Para obtener más información sobre el uso de estas cookies por Google Inc. y Mixpanel, se recomienda que consulte la política de privacidad de Google y Mixpanel con respecto al uso
                                    de Cookies.
                                </li>

                                <li>
                                    Las Cookies "red social" se utilizan para permitir que comparta los contenidos del sitio con sus amigos en los botones para compartir como Facebook y Twitter. Los proveedores de redes sociales después depositan Cookies en su
                                    dispositivo para obtener y recibir información sobre el uso de estos servicios que comparten. Para obtener más información sobre el uso de estas Cookies por los editores de redes sociales, se le invita a hacer referencia a
                                    la política de privacidad de los editores de redes sociales. Las Cookies de redes sociales son :
                                    <ul>
                                        <li>facebook.com _js_datr</li>
                                        <li>facebook.com _js_reg_*</li>
                                        <li>twitter.com _twitter_sess</li>
                                        <li>twitter.com external_referer</li>
                                        <li>twitter.com guest_id</li>
                                    </ul>
                                </li>
                            </ul>

                            <h5 id="cookie-policy_how-to-disable">10.3 – Desactivar las cookies</h5>

                            <h6>10.3.1 Desde la configuración del navegador web.</h6>
                            <p>La mayoría de los navegadores aceptan cookies de forma predeterminada. Sin embargo, puede configurar su navegador en cualquier momento para aceptar o rechazar Cookies caso por caso o rechazarlas sistemáticamente.</p>
                            <p>Sin embargo, si usted se niega a registrar las Cookies necesarias o eliminar aquellas que ya están registradas en su dispositivo, ya no podrá beneficiarse de un cierto número de funcionalidades del Sitio.</p>
                            <p>
                                Declinamos toda responsabilidad por las consecuencias vinculadas al funcionamiento degradado del Sitio que resulte de la imposibilidad de <strong>Catering Service Rio </strong> para registrar o consultar las Cookies necesarias y que
                                habría rechazado o eliminado.
                            </p>
                            <p>
                                A título orientativo, puede encontrar abajo consejos para desactivar las Cookies en su dispositivo. Si tiene alguna dificultad, por favor consulte la sección "Ayuda" de su navegador donde se puede encontrar más información sobre
                                cómo evitar que las Cookies y otras tecnologías similares.
                            </p>
                            <p>
                                Para Internet Explorer :<br />
                                https://support.microsoft.com/es-es/help/17442/windows-internet-explorer-delete-manage-cookies
                            </p>
                            <p>
                                Para Chrome :<br />
                                https://support.google.com/chrome/answer/95647?hl=es&amp;hlrm=es
                            </p>
                            <p>
                                Para Firefox :<br />
                                https://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-sitios-web-rastrear-preferencias
                            </p>
                            <p>
                                Para Safari :<br />
                                http://www.apple.com/legal/privacy/es/cookies/
                            </p>

                            <h6>10.3.2 A través de plataformas profesionales</h6>
                            <p>
                                También puede visitar www.youronlinechoices.com/en/controler-ses-cookies/ ofrecido por la European Digital Advertising Alliance (EDAA) para rechazar o aceptar las cookies utilizadas por las empresas registradas en esta
                                plataforma.
                            </p>
                        </article>

                        <article className="py-2">
                            <h3>Artículo 11 – Cambios en la Política de Datos Personales</h3>
                            Podemos revisar o complementar este documento para tener en cuenta nuevos propósitos o cambios en la legislación. Tales cambios serán efectivos a partir de su publicación. Para mantenerlo informado de los cambios, lea esta página
                            con regularidad.
                        </article>

                        <article className="py-2">
                            <h3>Artículo 12 – Contacta con <strong>Catering Service Rio </strong></h3>
                            <p>Por cualquier pregunta o comentario sobre este documento o preguntas sobre el uso de sus datos personales, usted puede contactarnos de la siguiente manera:</p>
                            <p>Para enviar un correo electrónico a nuestro Responsable de Protección de Datos, utilice por favor la siguiente dirección de correo electrónico: dpo@cateringservicerio.com</p>
                            <p>Para enviarnos una carta utilice por favor la siguiente dirección: Tacacorí de Alajuela, Alajuela, Costa Rica.</p>
                        </article>
                    </div>  
                </div>
            </div>
        </section>
    </Layout>
);

export default PrivacyPage;
